import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as Dashboard } from "../icons/dashboard.svg";
import { ReactComponent as Settings } from "../icons/settings.svg";
import { ReactComponent as Menu } from "../icons/menu.svg";
import styles from "./sidebar.module.css";

const Sidebar = ({ logout }: { logout: () => void }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      {open ? (
        <button
          className="fixed top-0 left-0 w-full h-full bg-gray-700 opacity-25 cursor-default z-10"
          aria-label="close menu"
          onClick={() => setOpen(false)}
        />
      ) : (
        <button
          className="sm:hidden absolute top-0 right-0 p-4 bg-gray-200 rounded"
          aria-label="open menu"
          onClick={() => setOpen(true)}
        >
          <Menu />
        </button>
      )}
      <div className="w-20 fixed sm:static" />
      <nav
        className={
          "h-screen w-20 bg-gray-300 flex mr-5 z-20 transition fixed left-0 flex-col justify-between " +
          styles.menu
        }
        aria-expanded={open ? "true" : "false"}
      >
        <ul className="pt-12 flex flex-col">
          <li>
            <NavLink
              to="/dashboard"
              onClick={() => setOpen(false)}
              activeClassName="bg-gray-600 hover:bg-gray-600 text-white"
              className="text-sm flex flex-col items-center text-center hover:bg-gray-400 pt-4 pb-3"
            >
              <Dashboard />
              <span className="mt-2">Dashboard</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/settings"
              onClick={() => setOpen(false)}
              activeClassName="bg-gray-600 hover:bg-gray-600 text-white"
              className="text-sm flex flex-col items-center text-center hover:bg-gray-400 pt-4 pb-3"
            >
              <Settings />
              <span className="mt-2">Settings</span>
            </NavLink>
          </li>
        </ul>
        <button className="p-4" onClick={logout}>
          Logout
        </button>
      </nav>
    </>
  );
};

export default Sidebar;
