export const storeJwt = (jwt: string) => {
  localStorage.setItem("jwt", jwt);
};

export const getJwt = (): string => {
  try {
    return localStorage.getItem("jwt") || "";
  } catch (_) {
    return "";
  }
};

export const removeJwt = () => {
  try {
    localStorage.removeItem("jwt");
  } catch (e) {}
};
