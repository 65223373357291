import { ApolloProvider } from "@apollo/react-hooks";
import ApolloClient, { gql } from "apollo-boost";
import React, { useEffect, useState, useMemo } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import Dashboard from "./components/Dashboard";
import LoginForm from "./components/LoginForm";
import RegisterForm from "./components/RegisterForm";
import Settings from "./components/Settings";
import Sidebar from "./components/Sidebar";
import { JwtContext } from "./contexts/JwtContext";
import { getJwt, storeJwt } from "./utils/jwt";

const App = () => {
  const [jwt, setJwt] = useState(() => getJwt());

  useEffect(() => {
    storeJwt(jwt);
  }, [jwt]);

  const client = useMemo(
    () =>
      new ApolloClient({
        uri: "/graphql",
        credentials: "same-origin",
        request: operation => {
          const token = jwt;
          operation.setContext({
            headers: {
              authorization: token ? `Bearer ${token}` : ""
            }
          });
        }
      }),
    [jwt]
  );

  return (
    <ApolloProvider client={client}>
      <JwtContext.Provider value={jwt}>
        <Router>
          {jwt ? (
            <div className="flex">
              <Sidebar
                logout={async () => {
                  await client.mutate({
                    mutation: gql`
                      mutation logout {
                        logout
                      }
                    `
                  });
                  setJwt("");
                }}
              />
              <main className="flex-1 mx-8 flex min-h-screen py-8 mt-10 sm:mt-0 justify-start">
                <Switch>
                  <Route exact path="/">
                    <Redirect to="/dashboard" />
                  </Route>
                  <Route exact path="/dashboard">
                    <Dashboard />
                  </Route>
                  <Route path="/settings">
                    <Settings />
                  </Route>
                </Switch>
              </main>
            </div>
          ) : (
            <main>
              <Switch>
                <Route exact path={["/login", "/"]}>
                  <LoginForm setJwt={setJwt} />
                </Route>
                <Route exact path="/register">
                  <RegisterForm setJwt={setJwt} />
                </Route>
                <Route>
                  <Redirect to="/login" />
                </Route>
              </Switch>
            </main>
          )}
        </Router>
      </JwtContext.Provider>
    </ApolloProvider>
  );
};

export default App;
