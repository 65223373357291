import React from "react";
import { ReactComponent as Check } from "../../icons/check.svg";
import styles from "./checkbox.module.css";

export const Checkbox = ({
  checked,
  children,
  onChange
}: {
  checked: boolean;
  children: any;
  onChange: (b: boolean) => void;
}) => {
  return (
    <label className="flex items-center">
      <div
        className={`w-4 h-4 bg-gray-400 rounded-sm inline-block mr-2 shadow flex items-center justify-center ${styles.label}`}
      >
        <input
          className="w-0 h-0"
          type="checkbox"
          checked={checked}
          onChange={e => onChange(e.target.checked)}
        />
        {checked ? <Check className="w-3 h-auto text-gray-900" /> : null}
      </div>
      {children}
    </label>
  );
};
