import React, { useState } from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import * as createTypes from "./types/createActivity";
import * as getTypes from "./types/getCustomersWithActivites";

const CREATE_ACTIVITY = gql`
  mutation createActivity(
    $name: String!
    $feePerHour: Int!
    $customerId: String!
  ) {
    createActivity(
      name: $name
      feePerHour: $feePerHour
      customerId: $customerId
    ) {
      id
      name
      feePerHour
    }
  }
`;

const GET_CUSTOMERS_WITH_ACTIVITIES = gql`
  query getCustomersWithActivites {
    me {
      id
      organization {
        id
        customers {
          id
          name
          activities {
            id
            name
            feePerHour
          }
        }
      }
    }
  }
`;

const Activities = () => {
  const { data } = useQuery<getTypes.getCustomersWithActivites>(
    GET_CUSTOMERS_WITH_ACTIVITIES
  );
  const [createActivity, { loading }] = useMutation<
    createTypes.createActivity,
    createTypes.createActivityVariables
  >(CREATE_ACTIVITY, { refetchQueries: ["getCustomersWithActivites"] });
  const [error, setError] = useState("");

  const [name, setName] = useState("");
  const [feePerHour, setFeePerHour] = useState("");
  const [customerId, setCustomerId] = useState("CHOOSE");
  const customers = data?.me?.organization?.customers || [];
  const activeCustomer = customers.find(c => c.id === customerId);
  const activities = activeCustomer ? activeCustomer.activities : [];

  return (
    <div className="mt-8 mb-12 w-64 flex flex-col mr-auto">
      <form
        onSubmit={async e => {
          e.preventDefault();
          if (!name || customerId === "CHOOSE") {
            return setError("Please fill out all fields");
          }
          let isNumber = false;
          try {
            isNumber = `${parseInt(feePerHour)}` === feePerHour;
          } catch (e) {}
          if (!isNumber) {
            return setError("Please fill out a number as fee");
          }
          setError("");
          try {
            await createActivity({
              variables: {
                name,
                feePerHour: parseInt(feePerHour),
                customerId: customerId
              }
            });
            setName("");
          } catch (e) {
            const message = e?.graphQLErrors?.[0]?.message || "Could not save";
            setError(message);
          }
        }}
      >
        <label className="flex flex-col">
          <span className="font-medium">Customer</span>
          <select
            value={customerId}
            onChange={e => setCustomerId(e.target.value)}
            className="bg-gray-200 p-1 mt-2 text-medium mb-4 rounded-sm flex-1 mr-2 min-w-0"
          >
            <option value="CHOOSE">Select customer</option>
            {customers.map(c => (
              <option key={c.id} value={c.id}>
                {c.name}
              </option>
            ))}
          </select>
        </label>
        <label className="flex flex-col">
          <span className="font-medium">Name</span>
          <input
            value={name}
            onChange={e => setName(e.target.value)}
            className="bg-gray-200 p-1 mt-2 text-medium mb-4 rounded-sm flex-1 mr-2 min-w-0"
            type="text"
          />
        </label>
        <label className="flex flex-col">
          <span className="font-medium">Fee per hour</span>
          <input
            value={feePerHour}
            onChange={e => setFeePerHour(e.target.value)}
            className="bg-gray-200 p-1 mt-2 text-medium mb-4 rounded-sm flex-1 mr-2 min-w-0"
            type="text"
          />
        </label>
        {error}
        <button
          className={`px-3 py-1 w-24 text-white shadow rounded-lg inline-block self-end justify-center flex items-center ${
            loading ? "text-gray-300 bg-gray-500" : "bg-gray-600"
          }`}
          disabled={loading}
        >
          Save
        </button>
      </form>
      <ul>
        {activities.map(activity => (
          <li
            className="bg-gray-300 rounded shadow px-2 py-1 mt-4"
            key={activity.id}
          >
            {activity.name} {activity.feePerHour}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Activities;
