import { useMutation } from "@apollo/react-hooks";
import { format } from "date-fns";
import isWeekend from "date-fns/isWeekend";
import gql from "graphql-tag";
import React from "react";
import { ReactComponent as Check } from "../icons/check.svg";
import { ReactComponent as Clear } from "../icons/clear.svg";
import { ReactComponent as Spinner } from "../icons/spinner.svg";
import { ReactComponent as Warning } from "../icons/error_outline.svg";
import { Checkbox } from "./Checkbox";
import * as t from "./types/removeEntry";
import {
  getEntries_me_reports_days_entries,
  getEntries_me_reports_days
} from "./types/getEntries";

const REMOVE_ENTRY = gql`
  mutation removeEntry($date: String!, $id: String!) {
    removeEntry(date: $date, id: $id) {
      id
      date
      entries {
        id
        time
        message
        customer {
          id
        }
      }
    }
  }
`;

const dateTime = (duration: number) =>
  `${Math.floor(duration / 60)}h${
    duration % 60 === 0 ? "" : " " + (duration % 60) + "m"
  }`;

const TimeEntry = ({
  entry,
  date
}: {
  entry: getEntries_me_reports_days_entries;
  date: string;
}) => {
  const [removeEntry, { loading }] = useMutation<
    t.removeEntry,
    t.removeEntryVariables
  >(REMOVE_ENTRY, {
    variables: {
      id: entry.id,
      date
    }
  });

  return (
    <li
      key={entry.id}
      className="flex justify-between mb-2 last:mb-0 items-baseline bg-gray-200 p-2 rounded-lg shadow text-sm"
    >
      <time dateTime={dateTime(entry.time)} className="mr-2 font-bold pt-1">
        {entry.time / 60}
      </time>
      <p className="flex-1 break-words overflow-hidden pt-1">{entry.message}</p>
      <p className="mr-2 p-1 bg-gray-100 rounded">{entry?.activity?.name}</p>
      <p className="break-words overflow-hidden mr-2">
        {entry?.customer?.name || ""}
      </p>
      <button
        className="self-start bg-white shadow text-white rounded-full w-8 h-8 text-gray-800 flex justify-center items-center"
        aria-label="remove entry"
        onClick={() => removeEntry()}
      >
        {loading ? (
          <Spinner className="max-w-full max-h-full w-4 h-auto" />
        ) : (
          <Clear className="max-w-full max-h-full w-4 h-auto" />
        )}
      </button>
    </li>
  );
};

const TimeEntryList = ({ days }: { days: getEntries_me_reports_days[] }) => {
  const [showOnlyFilled, setShowOnlyFilled] = React.useState(true);

  return (
    <section className="w-full">
      <Checkbox checked={showOnlyFilled} onChange={setShowOnlyFilled}>
        <span className="select-none">Show only days with entries</span>
      </Checkbox>
      <ul className="mt-4">
        {days
          .filter(
            (d: any) =>
              d.entries.length > 0 ||
              (showOnlyFilled ? null : !isWeekend(new Date(d.date)))
          )
          .reverse()
          .map((day: any) => {
            const sum =
              day.entries.reduce((sum: number, e: any) => sum + e.time, 0) / 60;
            const sumOk = sum >= 8;
            return (
              <li key={day.date} className="mb-4 bg-white p-4 rounded-lg">
                <header className="flex justify-between mb-2 pr-3">
                  <h4 className="font-medium">
                    {day.date}
                    <span className="font-normal ml-2 text-sm">
                      {format(new Date(day.date), "EEE")}
                    </span>
                  </h4>
                  <p className="flex justify-end items-center">
                    {sum}
                    <span
                      className="ml-2"
                      role="img"
                      aria-label={sumOk ? "ok symbol" : "warning symbol"}
                      title={
                        sumOk
                          ? ">=8 h reported"
                          : `need ${8 - sum} more hours for this day`
                      }
                    >
                      {sumOk ? (
                        <Check className="text-green-800" />
                      ) : (
                        <Warning className="text-yellow-600" />
                      )}
                    </span>
                  </p>
                </header>
                <ul className="mb-2">
                  {day.entries.map(
                    (entry: getEntries_me_reports_days_entries) => (
                      <TimeEntry key={entry.id} entry={entry} date={day.date} />
                    )
                  )}
                </ul>
              </li>
            );
          })}
      </ul>
    </section>
  );
};

export default TimeEntryList;
