import React from "react";
import { NavLink, Route, Switch } from "react-router-dom";
import Activities from "./Activities";
import ChangePassword from "./ChangePassword";
import Customers from "./Customers";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import * as types from "./types/getUser";

const GET_USER = gql`
  query getUser {
    me {
      id
      email
      acl
    }
  }
`;

const Settings = () => {
  const { data } = useQuery<types.getUser>(GET_USER);

  return (
    <div className="w-full flex flex-col">
      <h2 className="text-2xl text-left w-full">Settings</h2>
      <div className="w-full mt-4">
        <p>{data?.me?.email || ""}</p>
        <ul className="flex mt-2">
          {(data?.me?.acl || []).map(accessType => (
            <li key={accessType} className="mr-2 text-sm">
              {accessType}
            </li>
          ))}
        </ul>
      </div>
      <ul className="flex w-full mt-4">
        <li className="mr-2">
          <NavLink exact to="/settings" activeClassName="font-bold">
            General
          </NavLink>
        </li>
        <li className="mr-2">
          <NavLink exact to="/settings/customers" activeClassName="font-bold">
            Customers
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/settings/activities" activeClassName="font-bold">
            Activities
          </NavLink>
        </li>
      </ul>
      <div className="w-full flex flex-row flex-start flex-wrap">
        <Switch>
          <Route exact path="/settings">
            <ChangePassword />
          </Route>
          <Route exact path="/settings/customers">
            <Customers />
          </Route>
          <Route exact path="/settings/activities">
            <Activities />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default Settings;
