import gql from "graphql-tag";

export const dayFragment = gql`
  fragment dayFragment on DayEntries {
    id
    date
    entries {
      id
      time
      activity {
        id
        name
        feePerHour
      }
      customer {
        id
        name
        currency
      }
      message
    }
  }
`;
