import React, { useState } from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import * as createTypes from "./types/createCustomer";
import * as editTypes from "./types/editCustomer";
import * as getTypes from "./types/getCustomers";

const CREATE_CUSTOMER = gql`
  mutation createCustomer($name: String!, $currency: String) {
    createCustomer(name: $name, currency: $currency) {
      id
      name
      currency
    }
  }
`;

const EDIT_CUSTOMER = gql`
  mutation editCustomer($id: ID!, $name: String, $currency: String) {
    editCustomer(id: $id, name: $name, currency: $currency) {
      id
      name
      currency
    }
  }
`;

const GET_CUSTOMERS = gql`
  query getCustomers {
    me {
      id
      organization {
        id
        customers {
          id
          name
          currency
        }
      }
    }
  }
`;

const Customers = () => {
  const [createCustomer, { loading: loadingCreate }] = useMutation<
    createTypes.createCustomer,
    createTypes.createCustomerVariables
  >(CREATE_CUSTOMER, {
    refetchQueries: ["getCustomersWithActivites", "getCustomers"]
  });
  const [editCustomer, { loading: loadingEdit }] = useMutation<
    editTypes.editCustomer,
    editTypes.editCustomerVariables
  >(EDIT_CUSTOMER, {
    refetchQueries: ["getCustomersWithActivites", "getCustomers"]
  });
  const loading = loadingCreate || loadingEdit;
  const { data } = useQuery<getTypes.getCustomers>(GET_CUSTOMERS);
  const [error, setError] = useState("");

  const [name, setName] = useState("");
  const [currency, setCurrency] = useState("");
  const [customerId, setCustomerId] = useState("");
  let selectedCustomer = (data?.me?.organization?.customers || []).find(
    c => c.id === customerId
  );
  selectedCustomer = selectedCustomer || {
    __typename: "Customer",
    id: "",
    currency: "",
    name: ""
  };

  React.useEffect(() => {
    if (customerId) {
      const f = () => {
        setCustomerId("");
        setName("");
        setCurrency("");
      };
      setName(selectedCustomer!.name);
      setCurrency(selectedCustomer!.currency || "");
      window.addEventListener("click", f);
      return () => {
        window.removeEventListener("click", f);
      };
    }
  }, [customerId, selectedCustomer]);

  return (
    <div className="mt-8 mb-12 w-64 flex flex-col mr-auto">
      <form
        onSubmit={async e => {
          e.preventDefault();
          if (!name) {
            return setError("Please fill out all fields");
          }
          setError("");
          try {
            if (customerId) {
              await editCustomer({
                variables: {
                  id: customerId,
                  name,
                  currency
                }
              });
            } else {
              await createCustomer({
                variables: {
                  name,
                  currency
                }
              });
            }
            setName("");
            setCurrency("");
          } catch (e) {
            const message = e?.graphQLErrors?.[0]?.message || "Could not save";
            setError(message);
          }
        }}
      >
        <label className="flex flex-col">
          <span className="font-medium">Name</span>
          <input
            value={name}
            onClick={e => e.stopPropagation()}
            onChange={e => setName(e.target.value)}
            className="bg-gray-200 p-1 mt-2 text-medium mb-4 rounded-sm flex-1 mr-2 min-w-0"
            type="text"
          />
        </label>
        <label className="flex flex-col">
          <span className="font-medium">Currency</span>
          <input
            value={currency}
            onClick={e => e.stopPropagation()}
            onChange={e => setCurrency(e.target.value)}
            className="bg-gray-200 p-1 mt-2 text-medium mb-4 rounded-sm flex-1 mr-2 min-w-0"
            type="text"
          />
        </label>
        {error}
        <button
          onClick={e => e.stopPropagation()}
          className={`px-3 py-1 text-white shadow rounded-lg inline-block self-end justify-center flex items-center ${
            loading ? "text-gray-300 bg-gray-500" : "bg-gray-600"
          }`}
          disabled={loading}
        >
          {customerId ? "Save " + selectedCustomer.name : "Create customer"}
        </button>
      </form>
      <ul>
        {(data?.me?.organization?.customers || []).map(customer => (
          <li
            className={`bg-gray-300 rounded shadow mt-4 ${
              customerId === customer.id ? "bg-gray-400" : ""
            }`}
            key={customer.id}
          >
            <button
              className="w-full h-full text-left px-2 py-1"
              onClick={e => {
                e.stopPropagation();
                setCustomerId(customer.id);
              }}
            >
              {customer.name}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Customers;
