import React, { useState } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import * as t from "./types/changePassword";

const CHANGE_PASSWORD = gql`
  mutation changePassword($currentPassword: String!, $newPassword: String!) {
    changePassword(currentPassword: $currentPassword, newPassword: $newPassword)
  }
`;

const ChangePassword = () => {
  const [changePassword, { loading }] = useMutation<
    t.changePassword,
    t.changePasswordVariables
  >(CHANGE_PASSWORD);
  const [error, setError] = useState("");

  const [current, setCurrent] = useState("");
  const [new1, setNew1] = useState("");
  const [new2, setNew2] = useState("");

  return (
    <form
      className="mt-8 mb-12 w-64 flex flex-col mr-auto"
      onSubmit={async e => {
        e.preventDefault();
        if (!new1 || !new2 || !current) {
          return setError("Please fill out all fields");
        }
        if (new1 !== new2) {
          return setError("New passwords don't match");
        }
        setError("");
        try {
          await changePassword({
            variables: {
              currentPassword: current,
              newPassword: new1
            }
          });
          setCurrent("");
          setNew1("");
          setNew2("");
        } catch (e) {
          const message = e?.graphQLErrors?.[0]?.message || "Could not save";
          setError(message);
        }
      }}
    >
      <label className="flex flex-col flex-1">
        <span className="font-medium">Current password</span>
        <input
          value={current}
          onChange={e => setCurrent(e.target.value)}
          className="bg-gray-200 p-1 mt-2 text-medium mb-4 rounded-sm flex-1 mr-2 min-w-0"
          autoComplete="current-password"
          type="password"
        />
      </label>
      <label className="flex flex-col flex-1 mt-2">
        <span className="font-medium">New password</span>
        <input
          value={new1}
          onChange={e => setNew1(e.target.value)}
          className="bg-gray-200 p-1 mt-2 text-medium mb-4 rounded-sm flex-1 mr-2 min-w-0"
          autoComplete="new-password"
          type="password"
        />
      </label>

      <label className="flex flex-col flex-1">
        <span className="font-medium">Repeat new password</span>
        <input
          value={new2}
          onChange={e => setNew2(e.target.value)}
          className="bg-gray-200 p-1 mt-2 text-medium mb-4 rounded-sm flex-1 mr-2 min-w-0"
          autoComplete="new-password"
          type="password"
        />
      </label>
      {error}
      <button
        className={`px-3 py-1 w-24 text-white shadow rounded-lg inline-block self-end justify-center flex items-center ${
          loading ? "text-gray-300 bg-gray-500" : "bg-gray-600"
        }`}
        disabled={loading}
      >
        Save
      </button>
    </form>
  );
};

export default ChangePassword;
