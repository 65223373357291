import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import format from "date-fns/format";
import React, { useState } from "react";
import * as types from "./types/getGitActivites";

const GET_GIT_ACTIVITIES = gql`
  query getGitActivites($day: String!) {
    gitActivities(day: $day) {
      id
      time
      project
      ... on GitCommit {
        message
        branch
      }
    }
  }
`;
const GITLAB_APP_ID =
  "b192423aa4fec82e4778cbf32fbc63ce96e3e99b27cb5614d69b6a07e9e342d0";
const redirectUri =
  window.location.hostname === "localhost"
    ? "http://localhost:3501/oauth"
    : "https://time.peternycander.com/oauth";

const Timeline = ({ date }: { date: Date }) => {
  const { data, error, loading } = useQuery<
    types.getGitActivites,
    types.getGitActivitesVariables
  >(GET_GIT_ACTIVITIES, {
    variables: {
      day: date.toISOString().substring(0, 10)
    }
  });
  const [showGitlabButton, setShowGitlabButton] = useState(false);

  React.useEffect(() => {
    if (error?.graphQLErrors?.[0]?.message === "Missing token") {
      setShowGitlabButton(true);
    }
  }, [error]);

  const activities = data?.gitActivities || [];

  return (
    <>
      <div className="w-40 hidden sm:block" />
      <div className="flex-col items-center justify-start ml-8 hide-scrollbar w-40 hidden sm:flex overflow-y-scroll fixed right-0 top-0 bottom-0 pl-3 text-xs text-right">
        {loading ? (
          Array.from({ length: 5 }).map((_, i) => (
            <div
              key={i}
              className="bg-gray-200 w-full h-40 mt-2 rounded shadow"
            />
          ))
        ) : showGitlabButton ? (
          <a
            className="self-start p-2 btn-gitlab rounded shadow ml-auto mt-8 mr-2"
            href={`https://gitlab.com/oauth/authorize?client_id=${GITLAB_APP_ID}&redirect_uri=${encodeURIComponent(
              redirectUri
            )}&response_type=code&scope=api`}
          >
            Sign in to gitlab
          </a>
        ) : activities.length === 0 ? (
          <div>No git activity on {date.toISOString().substring(0, 10)}</div>
        ) : (
          <ul className="self-start text-left">
            {activities.map(activity => (
              <li
                key={activity.id}
                className="bg-gray-200 px-4 py-2 mt-2 w-full rounded shadow"
              >
                <div>
                  {format(new Date(activity.time), "HH:mm")} {activity.project}
                </div>
                <div className="mt-4">{activity.message}</div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default Timeline;
