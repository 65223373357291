import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import * as t from "./types/login";

const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password)
  }
`;

const LoginForm = ({ setJwt }: { setJwt: (s: string) => void }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [login] = useMutation<t.login, t.loginVariables>(LOGIN);
  const history = useHistory();

  return (
    <div className="flex h-screen justify-center items-center">
      <div className="w-full bg-white p-5 max-w-sm rounded-lg mx-10">
        <form
          className="flex flex-col"
          onSubmit={async e => {
            e.preventDefault();
            setError("");
            try {
              const result = await login({
                variables: {
                  email: username,
                  password
                }
              });
              const jwt = result?.data?.login;
              if (!jwt) {
                setError("Wrong username or password");
                return;
              }
              setJwt(jwt);
            } catch (e) {
              const error = e?.graphQLErrors?.[0]?.message;
              if (error) {
                setError(error);
              } else {
                setError("Server error");
              }
              return;
            }
            history.push("/dashboard");
          }}
        >
          <label className="flex flex-col justify-between mt-4">
            Username
            <input
              className="bg-gray-200 p-2"
              autoComplete="username"
              name="username"
              type="text"
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
          </label>
          <label className="flex flex-col justify-between mt-4">
            Password
            <input
              className="bg-gray-200 p-2"
              autoComplete="current-password"
              name="password"
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </label>
          <Link to="/register" className="self-end underline mt-2">
            Sign up
          </Link>
          {error ? (
            <p className="bg-red-400 p-3 mt-3 rounded-sm">{error}</p>
          ) : null}
          <button className="p-3 bg-gray-600 text-white shadow rounded-lg inline-block mt-8">
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
