import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.min.css";
import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import _debounce from "lodash/debounce";
import React, { useEffect, useState } from "react";
import { ReactComponent as Spinner } from "../icons/spinner.svg";
import * as t from "./types/reportTime";
import * as t2 from "./types/getReportFormData";
import { getEntries_me_reports_days } from "./types/getEntries";
import { dayFragment } from "../utils/fragments";

const REPORT_TIME = gql`
  mutation reportTime(
    $date: String!
    $customerId: String!
    $activityId: String!
    $time: Float!
    $message: String!
  ) {
    reportTime(
      date: $date
      customerId: $customerId
      activityId: $activityId
      time: $time
      message: $message
    ) {
      ...dayFragment
    }
  }
  ${dayFragment}
`;

const GET_DATA = gql`
  query getReportFormData {
    me {
      id
      organization {
        id
        customers {
          id
          name
          activities {
            id
            name
          }
        }
      }
    }
  }
`;

const set = _debounce((key: string, value: string) => {
  try {
    localStorage.setItem(key, value);
  } catch (e) {}
}, 250);

const get = (key: string) => {
  try {
    return localStorage.getItem(key);
  } catch (e) {}
  return "";
};

const ReportForm = ({
  days,
  date,
  setDate
}: {
  days: getEntries_me_reports_days[];
  date: Date;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
}) => {
  const [reportTime, { loading }] = useMutation<
    t.reportTime,
    t.reportTimeVariables
  >(REPORT_TIME);

  const { data, loading: loadingData } = useQuery<t2.getReportFormData>(
    GET_DATA
  );

  const [customerId, setCustomerId] = useState(
    () => get("CUSTOMER_ID") || "CHOOSE"
  );
  const [activityId, setActivityId] = useState(
    () => get("ACTIVITY_ID") || "CHOOSE"
  );
  const [message, setMessage] = useState(() => get("MESSAGE") || "");
  const [duration, setDuration] = useState(() => get("DURATION") || "0");
  const [error, setError] = useState("");

  useEffect(() => {
    set("CUSTOMER_ID", customerId);
  }, [customerId]);
  useEffect(() => {
    set("ACTIVITY_ID", activityId);
  }, [activityId]);
  useEffect(() => {
    set("DURATION", duration);
  }, [duration]);
  useEffect(() => {
    set("MESSAGE", message);
  }, [message]);

  const customers = data?.me?.organization?.customers || [];
  const activeCustomer = customers.find(c => c.id === customerId);
  const activities = activeCustomer ? activeCustomer.activities : [];

  return (
    <section className="p-4 rounded-lg bg-gray-500 mt-3 mb-12 w-full sticky top-0 shadow-md z-10">
      <form
        className="flex flex-col"
        onSubmit={async e => {
          e.preventDefault();
          if (customerId === "CHOOSE") {
            return setError("Select costumer");
          }
          if (activityId === "CHOOSE") {
            return setError("Select costumer");
          }
          const dateString = date.toISOString().substring(0, 10);
          const minutesAlreadyReported =
            days
              ?.find(d => d.date === dateString)
              ?.entries.reduce(
                (sum, e) => sum + (e.customer?.id === customerId ? e.time : 0),
                0
              ) || 0;
          const numberDuration = parseFloat(duration);
          if (
            isNaN(numberDuration) ||
            numberDuration <= 0 ||
            numberDuration + minutesAlreadyReported / 60 > 24
          ) {
            return setError("Select time > 0 h, costumer sum < 24 h");
          }
          setError("");
          try {
            await reportTime({
              variables: {
                date: dateString,
                customerId,
                activityId,
                time: numberDuration * 60,
                message: message || "Utveckling"
              }
            });
          } catch (e) {
            const error = e?.graphQLErrors?.[0]?.message;

            setError(error || "Could not save");
          }
        }}
      >
        <div className="flex flex-col sm:flex-row sm:justify-between">
          {customers.length > 0 ? (
            <label className="flex flex-col flex-1 pr-2">
              <span className="font-medium mb-1">Costumer</span>
              <select
                className="bg-gray-200 p-2 text-medium mb-4 rounded-sm flex-1 min-w-0"
                value={customerId}
                onChange={e => setCustomerId(e.target.value)}
              >
                <option value="CHOOSE">Select costumer</option>
                {customers.map(customer => (
                  <option key={customer.id} value={customer.id}>
                    {customer.name}
                  </option>
                ))}
              </select>
            </label>
          ) : (
            <div className="flex-1">
              {!loadingData && "Add new customers in Settings"}
            </div>
          )}

          {activities.length > 0 ? (
            <label className="flex flex-col flex-1">
              <span className="font-medium mb-1">Activity</span>
              <select
                className="bg-gray-200 p-2 text-medium mb-4 rounded-sm flex-1 min-w-0"
                value={activityId}
                onChange={e => setActivityId(e.target.value)}
              >
                <option value="CHOOSE">Select activity</option>
                {activities.map(a => (
                  <option key={a.id} value={a.id}>
                    {a.name}
                  </option>
                ))}
              </select>
            </label>
          ) : (
            <div className="flex-1">
              {!loadingData && activeCustomer && "Add activities in settings"}
            </div>
          )}
        </div>
        <div className="flex flex-col sm:flex-row sm:justify-between">
          <label className="flex flex-col flex-1 pr-2">
            <span className="font-medium mb-1">Date</span>
            <DatePicker
              dateFormat="yyyy-MM-dd"
              customInput={<input data-lpignore="true" />}
              autoComplete="off"
              className="bg-gray-200 p-2 text-medium mb-4 rounded-sm flex-1 min-w-0 w-full"
              selected={date}
              onChange={date => date && setDate(date)}
            />
          </label>

          <label className="flex flex-col flex-1">
            <span className="font-medium mb-1">Hours</span>
            <input
              type="text"
              data-lpignore="true"
              className="bg-gray-200 p-2 text-medium mb-4 rounded-sm flex-1 min-w-0"
              value={duration}
              onChange={e => setDuration(e.target.value)}
            />
          </label>
        </div>
        <label className="flex flex-col flex-1">
          <span className="font-medium mb-1">Message</span>
          <input
            type="text"
            data-lpignore="true"
            className="bg-gray-200 p-2 text-medium mb-4 rounded-sm"
            value={message}
            onChange={e => setMessage(e.target.value)}
          />
        </label>
        {error}
        <button
          className={`p-3 h-12 w-24 text-white shadow rounded-lg inline-block self-end justify-center flex items-center ${
            loading ? "text-gray-300 bg-gray-500" : "bg-gray-600"
          }`}
          disabled={loading}
        >
          Save{" "}
          {loading ? (
            <span>
              <Spinner className="w-5 h-auto ml-3" />
            </span>
          ) : null}
        </button>
      </form>
    </section>
  );
};

export default ReportForm;
