import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useState } from "react";
import { ReactComponent as Spinner } from "../icons/spinner.svg";
import { ReactComponent as ChevronRight } from "../icons/chevron_right.svg";
import { ReactComponent as ChevronLeft } from "../icons/chevron_left.svg";
import { removeJwt } from "../utils/jwt";
import ReportForm from "./ReportForm";
import TimeEntryList from "./TimeEntryList";
import { format, addMonths } from "date-fns";
import { getEntries, getEntriesVariables } from "./types/getEntries";
import { dayFragment } from "../utils/fragments";
import Timeline from "./Timeline";

const GET_ENTRIES = gql`
  query getEntries($yearMonth: String!) {
    me {
      id
      reports(yearMonth: $yearMonth) {
        id
        yearMonth
        days(includeEmpty: true) {
          ...dayFragment
        }
      }
    }
  }
  ${dayFragment}
`;

const Dashboard = () => {
  const [monthDate, setMonthDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());

  const { data, loading } = useQuery<getEntries, getEntriesVariables>(
    GET_ENTRIES,
    {
      variables: {
        yearMonth: format(monthDate, "yyyy-MM")
      }
    }
  );
  const days = data?.me?.reports?.days || [];
  React.useEffect(() => {
    if (data && data.me === null) {
      removeJwt();
      window.location.href = "/";
    }
  });
  const thisMonth = format(new Date(), "MMMM");
  const moneyThisMonth: { [currency: string]: number } = {};
  const timeThisMonth: { [customer: string]: number } = {};

  for (const day of days) {
    for (const entry of day.entries) {
      const currency = entry.customer?.currency || "SEK";
      const customer = entry.customer?.name || "Unknown";
      moneyThisMonth[currency] = moneyThisMonth[currency] || 0;
      moneyThisMonth[currency] +=
        (entry.time / 60) * (entry.activity?.feePerHour || 0);

      timeThisMonth[customer] = timeThisMonth[customer] || 0;
      timeThisMonth[customer] += entry.time / 60;
    }
  }
  return (
    <>
      <div className="w-full max-w-2xl">
        <div className="w-full flex mb-4 flex-col items-stretch sm:items-center sm:flex-row">
          <h2 className="text-2xl mr-5 text-left w-40">
            {format(monthDate, "MMMM")}
          </h2>
          <div className="flex">
            <button
              className="mr-3 shadow w-8 h-8 flex items-center justify-center rounded-sm bg-white"
              onClick={() => setMonthDate(addMonths(monthDate, -1))}
            >
              <ChevronLeft />
            </button>
            <button
              className="mr-3 shadow w-8 h-8 flex items-center justify-center rounded-sm bg-white"
              onClick={() => setMonthDate(addMonths(monthDate, 1))}
            >
              <ChevronRight />
            </button>
            {format(monthDate, "MMMM") !== thisMonth && (
              <button
                className="shadow px-3 h-8 flex items-center justify-center rounded-sm select-none"
                onClick={() => setMonthDate(new Date())}
              >
                {thisMonth}
              </button>
            )}
          </div>
        </div>
        <section className="w-full">
          <dl>
            {Object.keys(moneyThisMonth).map((currency: string) => (
              <div key={currency} className="mr-4 flex">
                <dt className="w-40 mr-4">{currency}</dt>
                <dd>{moneyThisMonth[currency]}</dd>
              </div>
            ))}
            {Object.keys(timeThisMonth).map((customer: string) => (
              <div key={customer} className="mr-4 flex">
                <dt className="w-40 mr-4">{customer}</dt>
                <dd>{timeThisMonth[customer]}</dd>
              </div>
            ))}
          </dl>
        </section>
        <ReportForm days={days} date={selectedDate} setDate={setSelectedDate} />
        {loading ? <Spinner /> : <TimeEntryList days={days} />}
      </div>
      <Timeline date={selectedDate} />
    </>
  );
};

export default Dashboard;
